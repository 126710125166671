import dayjs from "dayjs";

export enum SearchKeyType {
    SID = "sid",
    DID = "did",
    Account = "phone_number"
}

export interface ISearch {
    key_type: SearchKeyType
    key: string
    new_device: boolean
    new_account: boolean
    is_boss: boolean
    free_boss: boolean
    is_cashier_vip: boolean
    is_activity_vip: boolean
    date: string[]
}

export interface IRecordAccountShop {
    sid: number
    shop_name: string
    create_at: Date
    role: number
    is_new: boolean
    cashier_enable: boolean
    activity_enable: boolean
}

export interface IRecordAccount {
    phone_number: string
    is_new: boolean
    mark: string
    shops: IRecordAccountShop[]
}

export interface IIPInfo {
    ip: string
    province: string
    city: string
    district: string
    carrier: string
}

export interface IDevice {
    did: string
    app_version_code: number
    system_name: string
    system_version: string
    brand: string
    model: string
}

export interface IRecordApiState {
    count: number
    net_error: number
    waiting_max: number
    waiting_min: number
    waiting_avg: number
    exe_max: number
    exe_min: number
    exe_avg: number
}

export interface IRecordDetail {
    id: number
    url: string
    device: IDevice
    accounts?: IRecordAccount[] | null
    ip_info: IIPInfo
    api_status: IRecordApiState
}

export interface IRecord {
    id: number
    did: string
    ids: number[][]
    status: boolean[][]
    video_len: number
    url: string
    at: Date
    is_new: boolean
}

export interface IState {
    loading: boolean
    current_record: number
    searching: boolean
    record_refreshing: boolean
}

export interface IDeviceRecord {
    search: ISearch
    state: IState
    records: IRecord[]
    info?: IRecordDetail
}

export default class DeviceRecord implements IDeviceRecord {
    search: ISearch
    state: IState
    records: IRecord[]
    info?: IRecordDetail

    constructor() {
        this.search = {
            free_boss: false,
            is_activity_vip: false, is_boss: false, is_cashier_vip: false, new_account: false, new_device: false,
            date: [dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'day').format('YYYY-MM-DD')],
            key: "",
            key_type: SearchKeyType.Account
        }
        this.state = {current_record: 0, loading: false, searching: false, record_refreshing: false}
        this.records = []
    }
}

export interface IVideoListItem {
    id: number
    title: string
    desc: string
}
