import { ActionContext, ActionTree, Commit } from 'vuex'
import mqtt from '@/api/mqtt'
import Session, {ISession, IToken} from "@/lib/store/modules/session/typings";
import {ApiSession} from "@/api/http/session";
import {StoreSessionMutations} from "@/lib/store/modules/session/mutations";


export enum StoreSessionActions {
    doWxAuth = 'doWxAuth',
    doTokenRefresh = 'doTokenRefresh'
}

export default <ActionTree<Session, unknown>>{
    [StoreSessionActions.doWxAuth] ({ commit }, code: string): Promise<unknown> {
        return new Promise((resolve, reject) => {
            ApiSession.wxAuth(code).then((res: ISession): void => {
                res.token.expire = new Date(res.token.expire)
                onLogin(resolve, commit, res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    [StoreSessionActions.doTokenRefresh] (context: ActionContext<ISession, unknown>): Promise<IToken> {
        if (context.state.token.expire.valueOf() - new Date().valueOf() > 60 * 1000) {
            return new Promise((resolve) => resolve(context.state.token))
        }
        if (context.state.token.token === '') {
            return new Promise((resolve) => resolve(context.state.token))
        }
        if (context.state.token.expire.valueOf() < new Date().valueOf()) {
            return new Promise((resolve) => resolve(context.state.token))
        }
        return new Promise((resolve, reject) => {
            ApiSession.tokenRefresh(context.state.token).then((rs: IToken) => {
                rs.expire = new Date(rs.expire)
                context.commit(StoreSessionMutations.tokenRefresh, rs)
                resolve(rs)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

const onLogin = (resolve: (value: ISession) => void, commit: Commit, rs: ISession) => {
    rs.token.expire = new Date(rs.token.expire)
    mqtt.connect(rs.account.id, rs.token.token)
    commit(StoreSessionMutations.login, rs)
    resolve(rs)
}
