import axios, {AxiosRequestConfig, AxiosInstance, AxiosError, InternalAxiosRequestConfig} from 'axios'
import notification from 'ant-design-vue/es/notification'
import router from '@/lib/router'
import store from "@/lib/store";

interface ApiError {
    error: string
}

const request: AxiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://api.console.guandb.cn' : 'https://api.console.guandb.cn',
    timeout: 120000,
    withCredentials: false,
    responseType: 'json',
    headers: {
        'X-Gdb': 'console'
    }
} as AxiosRequestConfig)

const errHandler = (err: unknown): Promise<unknown> => {
    const ae = (err as AxiosError)
    if (ae.response !== undefined) {
        const res = ae.response
        const e = res.data as ApiError
        if (res.status === 403) {
            router.push({name: 'login'}).catch(null)
            notification.error({message: e.error})
        } else if (res.status === 404) {
            notification.error({message: ae.message})
            return Promise.reject(err)
        } else {
            notification.error({message: e ? e.error : ae.message})
        }
    }
    return Promise.reject(err)
}

request.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig<unknown> | Promise<InternalAxiosRequestConfig<unknown>> => {
    const session = store.state.session
    config.headers.Authorization = session.token.token
    config.headers['wx-open-id'] = session.account.wx_open_id
    return config
}, errHandler)

request.interceptors.response.use(r => r, errHandler)

export default request
