import {MutationTree} from "vuex";
import DeviceRecord, {
    IRecord,
    IRecordDetail,
    ISearch
} from "@/layouts/workspace/follow/device_session_record/module/typings";


export default <MutationTree<DeviceRecord>>{
    updateSearch(state: DeviceRecord, res: ISearch) {
        state.search = res
        state.state.searching = true
        state.records = []
        state.info = undefined
        state.state.current_record = -1
    },
    updateRecords(state: DeviceRecord, list: IRecord[]) {
        state.records = list
    },
    searchComplete(state: DeviceRecord) {
        state.state.searching = false
    },
    onLoad(state: DeviceRecord) {
        state.state.loading = true
    },
    onLoadOver(state: DeviceRecord) {
        state.state.loading = false
    },
    updateDetail(state: DeviceRecord, d:IRecordDetail) {
        state.info = d
    }
}
