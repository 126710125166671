import Layout from './AuthLayout.vue'
import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: '/auth',
        name: 'login',
        meta: { requireAuth: false },
        component: Layout
    }
]
