import {
    IBarDate,
} from "@/layouts/workspace/dashboard/dashboard";
import StackBar from "@/layouts/workspace/dashboard/stackBar";

export default class MixBar extends StackBar {

    tooltip(param: any[]): string | HTMLElement | HTMLElement[] {
        let all = 0
        param.forEach(p => all += p.value)
        let s = param[0].axisValueLabel + `<br/>`
        for (const e of param) {
            let v = e.value * 100 / all
            v = isNaN(v) ? 0 : v
            s += e.marker + ` ${e.seriesName}: ${e.value}<br />`
        }
        return s
    }

    data(): IBarDate[] {
        const arr = []
        for (const st of this.sqlTpl) {
            arr.unshift({
                type: st.type,
                name: st.name,
                data: this.mode.dataFormat(st.chartData!),
                showBackground: true,
                stack: undefined
            })
        }
        return arr;
    }

}