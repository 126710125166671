import NotFound from './NotFound.vue'
import Unsupported from './Unsupported.vue'
import { RouteRecordRaw } from 'vue-router'

export default <Array<RouteRecordRaw>>[
    {
        path: '',
        redirect: { name: 'notfound' }
    },
    {
        path: '404',
        name: 'notfound',
        meta: { requireAuth: false },
        component: NotFound
    },
    {
        path: 'unsupported',
        name: 'unsupported',
        meta: { requireAuth: false },
        component: Unsupported
    }
]
