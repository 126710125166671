import {req} from "@/lib/utils";
import {
    IBarContext,
    IBarDate,
    ISQLData,
    ISQLDataItem,
    ISQLSeries
} from "@/layouts/workspace/dashboard/dashboard";
import {IModeItem} from "@/layouts/workspace/dashboard/modes";

export default class StackBar implements IBarContext {
    mode: IModeItem;
    readonly modes: IModeItem[];
    readonly span: number;
    readonly title: string;
    protected sqlTpl: ISQLSeries[];
    protected draw: () => void
    protected showDetail: (title: string, d: ISQLData) => void
    protected spin: (spin: boolean) => void

    constructor(title: string, span: number, modes: IModeItem[], sqlTpl: ISQLSeries[]) {
        this.title = title
        this.modes = modes
        this.mode = this.modes[0]
        this.sqlTpl = sqlTpl
        this.span = span
        this.draw = (): void => {
            // empty
        }
        this.showDetail = (): void => {
            // empty
        }
        this.spin = (): void => {
            // empty
        }
    }

    onSpin(fn: (spin: boolean) => void): void {
        this.spin = fn
    }

    category(): string[] {
        return this.mode.category();
    }

    data(): IBarDate[] {
        const arr = []
        for (const st of this.sqlTpl) {
            arr.unshift({
                type: st.type,
                name: st.name,
                data: this.mode.dataFormat(st.chartData!),
                showBackground: true,
                stack: 'all',
            })
        }
        return arr;
    }

    tooltip(param: any[]): string | HTMLElement | HTMLElement[] {
        let all = 0
        param.forEach(p => all += p.value)
        let s = param[0].axisValueLabel + ` 共计：${all}<br/>`
        for (const e of param) {
            let v = e.value * 100 / all
            v = isNaN(v) ? 0 : v
            s += e.marker + ` ${e.seriesName}: ${e.value} (${Math.round(v)}%)<br />`
        }
        return s
    }

    load(): Promise<unknown> {
        return new Promise((resolve, reject) => {
            this.spin(true)
            Promise.all(this.sqlTpl.map(s => {
                return new Promise((resolve, reject) => {
                    req({
                        url: 'query',
                        method: 'POST',
                        data: {db: s.series.db, sql: this.mode.parseGatherSql(s.series.sql)},
                        success: (r: ISQLData) => {
                            s.chartData = r.list.map((i): ISQLDataItem => ({at: new Date(i[0]), v: i[1] as number}))
                            resolve(r)
                            this.draw()
                        },
                        fail: err => reject(err)
                    })
                })
            })).then(resolve).catch(reject).finally(() => this.spin(false))
        })
    }

    setMode(mode: IModeItem): void {
        this.mode = mode
    }

    setComplete(fn: () => void): void {
        this.draw = fn
    }

    setDetailComplete(fn: (title: string, d: ISQLData) => void): void {
        this.showDetail = fn
    }

    onBarClick(seriesIndex: number, dataIndex: number): void {
        seriesIndex = this.sqlTpl.length - seriesIndex - 1
        req({
            url: 'query',
            method: 'POST',
            data: {
                db: this.sqlTpl[seriesIndex].detail.db,
                sql: this.mode.parseDetailSql(this.sqlTpl[seriesIndex].detail.sql, dataIndex)
            },
            success: (r: ISQLData) => {
                this.showDetail(this.sqlTpl[seriesIndex].name, r)
            }
        })
    }
}