import mqtt from "@/api/mqtt";

export interface ISession {
    account: IAccount
    token: IToken
    menu: IMenu[]
}

export interface IAccount {
    id: string
    wx_open_id: string
    head_img_url: string
    nick_name: string
}

export interface IToken {
    token: string
    expire: Date
}

export interface IMenu {
    key: string
    icon: string
    title: string
    path: string
    children: IMenu[]
}

export default class Session implements ISession {
    account: IAccount
    token: IToken
    menu: IMenu[]

    constructor() {
        try {
            const s = JSON.parse(localStorage.session) as ISession
            this.account = s.account
            this.token = s.token
            this.token.expire = new Date(s.token.expire)
            this.menu = s.menu
            mqtt.connect(s.account.id, s.token.token)
        } catch (e) {
            this.account = {id: '', head_img_url: "", nick_name: "", wx_open_id: ''}
            this.token = {
                expire: new Date(),
                token: ''
            }
            this.menu = []
        }

    }
}
