import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'device/session/live',
        name: 'device.session.live',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "device_session_live" */ './device_session_live/DeviceSessionLive.vue')
    },
    {
        path: 'device/session/record',
        name: 'device.session.record',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "device_session_record" */ './device_session_record/DeviceSessionRecord.vue')
    },
    {
        path: 'shop/free/followup',
        name: 'shop.free.followup',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "shop_free_followup" */ './free_shop_follow_up/FreeShopFollowUp.vue')
    },
    {
        path: 'cashier/lite/followup',
        name: 'cashier.lite.followup',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cashier_lite_followup" */ './cashier_lite_followup/CashierLiteFollowUp.vue')
    },
]
