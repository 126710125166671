import {Module} from "vuex";
import Session, {ISession} from "@/lib/store/modules/session/typings";
import mutations from "@/lib/store/modules/session/mutations";
import actions from "@/lib/store/modules/session/actions";

export default <Module<ISession, unknown>>{
    state: new Session(),
    mutations,
    actions
}
