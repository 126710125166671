import {RouteRecordRaw} from "vue-router";
import Layout from './Exception.vue'
import views from './views'

export default <Array<RouteRecordRaw>>[
    {
        path: '/exception',
        meta: { requireAuth: false },
        component: Layout,
        children: views
    }
]
