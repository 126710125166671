import notification from "ant-design-vue/es/notification";

declare global {
    interface Window {
        webkitSpeechRecognition: any
    }
}

export interface IVoiceListener {
    (rs: string): void
}

export interface IVoiceStatusListener {
    (status: boolean): void
}

class voice {
    recognition = new window.webkitSpeechRecognition();
    status = false
    listeners: IVoiceListener[] = []
    sls: IVoiceStatusListener[] = []

    constructor() {
        this.recognition.lang = 'cmn-Hans-CN'
        this.recognition.onspeechend = (e: Event) => {
            console.log(e)
        }
        this.recognition.onerror = (e: unknown) => {
            console.log(e)
        }
        this.recognition.onend = (e: Event) => {
            console.log(e)
            if (this.status) this.recognition.start()
        }

        this.recognition.onresult = (e: SpeechRecognitionEvent) => {
            console.log(e.results[0][0].transcript, e.results)
            this.listeners.forEach(f => f(e.results[0][0].transcript))
        }

    }

    start() {
        console.info('start')
        navigator.getUserMedia({audio: true}, (stream: MediaStream) => {
            this.status = true
            this.recognition.start()
            this.sls.forEach(f => f(true))
            this.speak('语音指令开始')
        }, (err: MediaStreamError) => {
            notification.error({message: err.message})
        })
    }

    stop() {
        console.info('stop')
        this.status = false
        this.recognition.stop()
        this.sls.forEach(f => f(false))
        this.speak('语音指令结束')
    }

    addListener(fun: IVoiceListener) {
        this.listeners.push(fun)
    }

    removeListener(fun: IVoiceListener) {
        this.listeners = this.listeners.filter(i => i != fun)
    }

    addStatusListener(fun: IVoiceStatusListener) {
        this.sls.push(fun)
    }

    removeStatusListener(fun: IVoiceStatusListener) {
        this.sls = this.sls.filter(i => i != fun)
    }

    speak(msg: string) {
        const speaker = new SpeechSynthesisUtterance(msg)
        window.speechSynthesis.speak(speaker)
    }
}

let v: voice | undefined = undefined

export const useVoice = (): voice => {
    if (v === undefined) v = new voice()
    return v!
}
