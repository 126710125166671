import MixBar from "@/layouts/workspace/dashboard/mixBar";
import {DateMode, HourMode, MonthMode} from "@/layouts/workspace/dashboard/modes";
import StackBar from "@/layouts/workspace/dashboard/stackBar";
import {IChartContext} from "@/layouts/workspace/dashboard/dashboard";

export const charts: { [key: string]: IChartContext[] } = {
    djhdb:[
        new StackBar('成交趋势',24,[new DateMode(),new MonthMode()],[
            {
                name:'开户/邀请',
                type:'bar',
                series:{
                    db:'core',
                    sql:`select date_trunc('_TS_',create_at) ts,sum((order_info->'amount'->'total')::float/100) v from activity.user_invites where order_info is not null and create_at>_ROUND_ group by ts`
                },
                detail:{
                    db:'market',
                    sql:'select now()'
                }
            },
            {
                name:'活动续费',
                type:'bar',
                series:{
                    db:'core',
                    sql:`select date_trunc('_TS_',create_at) ts,sum((order_info->'amount'->'total')::float/100) v from activity.exp_packages where order_info is not null and order_info->>'trade_type'='NATIVE' and create_at>_ROUND_ group by ts`
                },
                detail:{
                    db:'market',
                    sql:'select now()'
                }
            },
            {
                name:'短信充值',
                type:'bar',
                series:{
                    db:'core',
                    sql:`select date_trunc('_TS_',create_at) ts,sum((order_info->'amount'->'total')::float/100) v from activity.activation_sms where order_info is not null and create_at>_ROUND_ group by ts`
                },
                detail:{
                    db:'market',
                    sql:'select now()'
                }
            },
        ]),
        new MixBar('邀请趋势',24,[new DateMode(),new MonthMode()],[
            {
                name:'成交',
                type:'bar',
                series:{
                    db:'core',
                    sql:`select date_trunc('_TS_',create_at) ts,count(case when order_info is not null then 1 else null end) v from activity.user_invites where create_at>_ROUND_ group by ts`
                },
                detail:{
                    db:'market',
                    sql:'select now()'
                }
            },
            {
                name:'手机号',
                type:'bar',
                series:{
                    db:'core',
                    sql:`select date_trunc('_TS_',create_at) ts,count(distinct invite_phone_number) v from activity.user_invites where create_at>_ROUND_ group by ts`
                },
                detail:{
                    db:'market',
                    sql:'select now()'
                }
            },
            {
                name:'分享次数',
                type:'bar',
                series:{
                    db:'core',
                    sql:`select date_trunc('_TS_',create_at) ts,count(1) v from activity.user_invites where create_at>_ROUND_ group by ts`
                },
                detail:{
                    db:'market',
                    sql:'select now()'
                }
            },
        ])
    ],
    complex: [
        new MixBar('老板账号增长趋势', 12, [new DateMode(), new MonthMode()], [
            {
                name: '新注册老板',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',account_create_at) ts,count(1) v from union_account where role=1 and account_create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'market',
                    sql: 'select aid,phone_number,did "绑定设备",c."微信昵称备注" AS "微信备注",m."微信昵称备注" AS "企微备注",wework_user_id as "关联员工" from union_accounts u left join "用户账号备注名" m ON m."手机号" = u.phone_number left join "用户账号备注" c ON c."手机号" = u.phone_number where role=1 and account_create_at between _ROUND_ order by account_create_at desc'
                },
            }
        ]),
        new StackBar('管店宝交易', 12, [new DateMode(), new MonthMode()], [
            {
                name: '下单',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) v from spread.cloud_storage_trades where transaction_id is null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,amount from spread.cloud_storage_trades where transaction_id is null and create_at between _ROUND_ order by create_at desc'
                },
            },
            {
                name: '成交',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) v from spread.cloud_storage_trades where transaction_id is not null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,amount from spread.cloud_storage_trades where transaction_id is not null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new MixBar('开单趋势', 24, [new HourMode(), new DateMode(), new MonthMode()], [
            {
                name: '开单数量',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) from bills where create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: "select * from bills where create_at between _ROUND_ order by create_at desc"
                },
            },
            {
                name: '开单门店数量',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(distinct sid) from bills where create_at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: "select now()"},
            },
        ]),
        new StackBar('商城交易', 12, [new DateMode(), new MonthMode()], [
            {
                name: '下单',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) v from mall.goods_businesses where trade is null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is null and create_at between _ROUND_ order by create_at desc'
                },
            },
            {
                name: '成交',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) v from mall.goods_businesses where trade is not null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is not null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new StackBar('商城交易金额', 12, [new DateMode(), new MonthMode()], [
            {
                name: '下单',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,sum(amount) v from mall.goods_businesses where trade is null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is null and create_at between _ROUND_ order by create_at desc'
                },
            },
            {
                name: '成交',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,sum(amount) v from mall.goods_businesses where trade is not null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is not null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new StackBar('商城交易平均单价', 12, [new DateMode(), new MonthMode()], [
            {
                name: '下单',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,avg(amount) v from mall.goods_businesses where trade is null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is null and create_at between _ROUND_ order by create_at desc'
                },
                chartData: [],
                detailData: {columns: [], list: []}
            },
            {
                name: '成交',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,avg(amount) v from mall.goods_businesses where trade is not null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is not null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new StackBar('商城流量', 12, [new DateMode(), new MonthMode()], [
            {
                name: '商城主页浏览',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',at) ts,sum(amount) v from mall.statistics where id='views' and at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: 'select now()'},
            },
            {
                name: '分享',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',at) ts,sum(amount) v from mall.statistics where id='shares' and at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: 'select now()'},
            },
            {
                name: '成交',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',at) ts,sum(amount) v from mall.statistics where id='deals' and at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: 'select now()'},
            }
        ]),
        new StackBar('商城交易门店', 12, [new DateMode(), new MonthMode()], [
            {
                name: '下单',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(distinct sid) v from mall.goods_businesses where trade is null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is null and create_at between _ROUND_ order by create_at desc'
                },
            },
            {
                name: '成交',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(distinct sid) v from mall.goods_businesses where trade is not null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select sid,gid from mall.goods_businesses where trade is not null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new MixBar('商城维护门店', 12, [new DateMode(), new MonthMode()], [
            {
                name: '门店',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(distinct sid) v from mall.goods where delete_at is null and create_at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: 'select now()'},
            },
        ]),
    ],


    lite: [
        new StackBar('新增趋势', 12, [new DateMode(), new MonthMode()], [
            {
                name: '游客',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: 'select date_trunc(\'_TS_\',create_at) ts,count(1) v from cashier_lite.users where shop_name is null and delete_at is null and create_at>_ROUND_ group by ts order by ts'
                },
                detail: {
                    db: 'market',
                    sql: 'select wx_union_id,c.remark,jsonb_path_query_array(c.follow_users,\'$.userid\') as "关联企微员工",u.follow_at from cashier_lite.users u left join wework.contacts c on c.unionid=u.wx_union_id where u.shop_name is null and c.userid is not null and u.create_at between _ROUND_ order by u.create_at desc'
                },
            },
            {
                name: '用户',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: 'select date_trunc(\'_TS_\',create_at) ts,count(1) v from cashier_lite.users where shop_name is not null and delete_at is null and create_at>_ROUND_ group by ts order by ts'
                },
                detail: {
                    db: 'market',
                    sql: 'select wx_union_id,phone_number,qr,c."微信昵称备注" AS "微信备注",m.remark AS "企微备注",create_at from cashier_lite.users u left join wework.contacts m ON m.unionid = u.wx_union_id left join "用户账号备注" c ON c."手机号" = u.phone_number where shop_name is not null and delete_at is null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new StackBar('活跃用户', 12, [new DateMode(), new MonthMode()], [
            {
                name: '浏览',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',active_at) ts,count(1) from cashier_lite.users where wx_union_id not in (select distinct uid from cashier_lite.bills where create_at>_ROUND_) and active_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'market',
                    sql: 'select wx_union_id,c.remark,jsonb_path_query_array(c.follow_users,\'$.userid\') as "关联企微员工",u.follow_at from cashier_lite.users u left join wework.contacts c on c.unionid=u.wx_union_id where u.wx_union_id not in (select distinct uid from cashier_lite.bills where create_at between _ROUND_) and u.create_at between _ROUND_ order by u.create_at desc'
                },
            },
            {
                name: '开票',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',active_at) ts,count(1) from cashier_lite.users where wx_union_id in (select distinct uid from cashier_lite.bills where create_at>_ROUND_) and active_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'market',
                    sql: 'select wx_union_id,c.remark,jsonb_path_query_array(c.follow_users,\'$.userid\') as "关联企微员工",u.follow_at from cashier_lite.users u left join wework.contacts c on c.unionid=u.wx_union_id where u.wx_union_id in (select distinct uid from cashier_lite.bills where create_at between _ROUND_) and u.create_at between _ROUND_ order by u.create_at desc'
                },
            },
        ]),
        new MixBar('开单趋势', 24, [new HourMode(), new DateMode(), new MonthMode()], [
            {
                name: '开单数量',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) from cashier_lite.bills where create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: "select bid,uid,bill_type,amount,create_at from cashier_lite.bills where create_at between _ROUND_ order by create_at desc"
                },
            },
            {
                name: '开单用户数量',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(distinct uid) from cashier_lite.bills where create_at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: "select * from cashier_lite.users where wx_union_id in (select distinct uid from cashier_lite.bills where create_at between _ROUND_)"},
            },
        ]),
        new StackBar('账单付费趋势', 12, [new MonthMode()], [
            {
                name: '付费',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) from cashier_lite.invoices where trade is not null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: "select invoice_id,mon,amount,bill_count from cashier_lite.invoices where trade is not null and create_at between _ROUND_ group by ts order by create_at desc"
                },
            },
            {
                name: '流失',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) from cashier_lite.invoices where trade is null and create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: 'select invoice_id,mon,amount,bill_count from cashier_lite.invoices where trade is not null and create_at between _ROUND_ group by ts order by create_at desc'
                },
            },
        ]),
        new StackBar('用户趋势', 12, [new MonthMode()], [
            {
                name: '游客',
                type: 'bar',
                series: {
                    db: 'market',
                    sql: "select date_trunc('_TS_',mon) ts,guest from cashier_lite.status_gather_by_month where mon>_ROUND_"
                },
                detail: {db: 'market', sql: 'select now()'},
            },
            {
                name: '付费',
                type: 'bar',
                series: {
                    db: 'market',
                    sql: "select date_trunc('_TS_',mon) ts,payed from cashier_lite.status_gather_by_month where mon>_ROUND_"
                },
                detail: {db: 'market', sql: 'select now()'},
            },
            {
                name: '体验',
                type: 'bar',
                series: {
                    db: 'market',
                    sql: "select date_trunc('_TS_',mon) ts,trailer from cashier_lite.status_gather_by_month where mon>_ROUND_"
                },
                detail: {db: 'market', sql: 'select now()'},
            },
            {
                name: '流失',
                type: 'bar',
                series: {
                    db: 'market',
                    sql: "select date_trunc('_TS_',mon) ts,outflow from cashier_lite.status_gather_by_month where mon>_ROUND_"
                },
                detail: {db: 'market', sql: 'select now()'},
            },
        ]),
    ],

    tony:[
        new StackBar('新增趋势', 12, [new DateMode(), new MonthMode()], [
            {
                name: '游客',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: 'select date_trunc(\'_TS_\',create_at) ts,count(1) v from tony_bookkeeping.hairdressers where phone_number is null and create_at>_ROUND_ group by ts order by ts'
                },
                detail: {
                    db: 'market',
                    sql: 'select union_id,c.remark,jsonb_path_query_array(c.follow_users,\'$.userid\') as "关联企微员工" from tony_bookkeeping.hairdressers u left join wework.contacts c on c.unionid=u.union_id where u.phone_number is null and c.userid is not null and u.create_at between _ROUND_ order by u.create_at desc'
                },
            },
            {
                name: '用户',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: 'select date_trunc(\'_TS_\',create_at) ts,count(1) v from tony_bookkeeping.hairdressers where phone_number is not null and create_at>_ROUND_ group by ts order by ts'
                },
                detail: {
                    db: 'market',
                    sql: 'select wx_union_id,phone_number,qr,c."微信昵称备注" AS "微信备注",m.remark AS "企微备注",create_at from tony_bookkeeping.hairdressers u left join wework.contacts m ON m.unionid = u.union_id left join "用户账号备注" c ON c."手机号" = u.phone_number where phone_number is not null and create_at between _ROUND_ order by create_at desc'
                },
            }
        ]),
        new StackBar('活跃用户', 12, [new DateMode(), new MonthMode()], [
            {
                name: '浏览',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',active_at) ts,count(1) from tony_bookkeeping.hairdressers where open_id not in (select distinct open_id from tony_bookkeeping.bills where create_at>_ROUND_) and active_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'market',
                    sql: 'select union_id,c.remark,jsonb_path_query_array(c.follow_users,\'$.userid\') as "关联企微员工" from tony_bookkeeping.hairdressers u left join wework.contacts c on c.unionid=u.union_id where u.open_id not in (select distinct open_id from tony_bookkeeping.bills where create_at between _ROUND_) and u.create_at between _ROUND_ order by u.create_at desc'
                },
            },
            {
                name: '开票',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',active_at) ts,count(1) from tony_bookkeeping.hairdressers where open_id in (select distinct open_id from tony_bookkeeping.bills where create_at>_ROUND_) and active_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'market',
                    sql: 'select union_id,c.remark,jsonb_path_query_array(c.follow_users,\'$.userid\') as "关联企微员工" from tony_bookkeeping.hairdressers u left join wework.contacts c on c.unionid=u.union_id where u.open_id in (select distinct open_id from tony_bookkeeping.bills where create_at between _ROUND_) and u.create_at between _ROUND_ order by u.create_at desc'
                },
            },
        ]),
        new MixBar('开单趋势', 24, [new HourMode(), new DateMode(), new MonthMode()], [
            {
                name: '开单数量',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(1) from tony_bookkeeping.bills where create_at>_ROUND_ group by ts"
                },
                detail: {
                    db: 'core',
                    sql: "select bid,open_id,service_type,amount,commission,create_at from tony_bookkeeping.bills where create_at between _ROUND_ order by create_at desc"
                },
            },
            {
                name: '开单用户数量',
                type: 'bar',
                series: {
                    db: 'core',
                    sql: "select date_trunc('_TS_',create_at) ts,count(distinct open_id) from tony_bookkeeping.bills where create_at>_ROUND_ group by ts"
                },
                detail: {db: 'core', sql: "select * from tony_bookkeeping.hairdressers where open_id in (select distinct open_id from tony_bookkeeping.bills where create_at between _ROUND_)"},
            },
        ]),
    ]
}
