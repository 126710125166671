import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'cms/index',
        name: 'cms.index',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_index" */ './index/Index.vue')
    },
    {
        path: 'cms/shop/video',
        name: 'cms.shop.video',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_shop_video" */ './shop_video/ShopVideo.vue')
    },
    {
        path: 'cms/activity/templates',
        name: 'cms.activity.templates',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_activity_templates" */ './activity/template/ActivityTemplate.vue')
    },
    {
        path: 'cms/activity/titles',
        name: 'cms.activity.titles',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_activity_titles" */ './activity/title/ActivityTitles.vue')
    },
    {
        path: 'cms/activity/introductions',
        name: 'cms.activity.introductions',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_activity_introductions" */ './activity/introduction/ActivityIntroductions.vue')
    },
    {
        path: 'cms/image_tool',
        name: 'cms.image_tool',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_image_tool" */ './image_tool/ImageTool.vue')
    },
    {
        path: 'cms/themes',
        name: 'cms.themes',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_themes" */ './theme/Theme.vue')
    },
    {
        path: 'cms/picture',
        name: 'cms.picture',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_picture" */ './activity/picture/Pictures.vue')
    },
    {
        path: 'cms/gift',
        name: 'cms.gift',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_gift" */ './activity/gift/Gifts.vue')
    },
    {
        path: 'cms/activities',
        name: 'cms.activities',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "cms_activities" */ './activity/acitvities/ActivitiesPage.vue')
    }
]
