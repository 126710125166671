import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'djhdb/activities',
        name: 'djhdb.activities',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "djhdb_activities" */ './activities/DjhdbActivitiesPage.vue')
    },
]
