import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {IRecord, IRecordDetail, ISearch} from "@/layouts/workspace/follow/device_session_record/module/typings";

export async function query(info: ISearch): Promise<IRecord[]> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "device/session/search",
        method: 'post',
        data: info
    } as AxiosRequestConfig));
    return rs.data;
}

export async function info(id: number): Promise<IRecordDetail> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "device/session/info",
        method: 'get',
        params: {id: id}
    } as AxiosRequestConfig));
    return rs.data;
}
