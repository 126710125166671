import {ISession, IToken} from "@/lib/store/modules/session/typings";
import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";

export class ApiSession {

    /**
     * 微信登录
     * @param code
     */
    static async wxAuth(code: string): Promise<ISession> {
        const rs = await request.request<unknown, AxiosResponse, unknown>(({
            url: "wx/auth",
            params: {code: code}
        } as AxiosRequestConfig));
        return rs.data;
    }

    /**
     * Token刷新
     * @param token
     */
    static async tokenRefresh(token: IToken): Promise<IToken> {
        const rs = await request.request<unknown, AxiosResponse, unknown>(({
            url: "token/refresh",
            method: 'put',
            data: token
        } as AxiosRequestConfig));
        return rs.data;
    }
}
