import {createStore, Store, useStore as us} from 'vuex'
import session from "@/lib/store/modules/session";
import deviceRecord from "@/layouts/workspace/follow/device_session_record/module"
import {State} from "@/lib/store/typings";
import {InjectionKey} from "vue";

export const key: InjectionKey<Store<State>> = Symbol()
export default createStore<State>({
    modules: {
        session,
        deviceRecord
    },
    strict: process.env.NODE_ENV !== 'production'
})

export function useStore(): Store<State> {
    return us(key)
}
