import {RouteRecordRaw} from 'vue-router'
import Layout from './WorkspaceLayout.vue'
import follow from './follow'
import Dashboard from "./Dashboard.vue";
import growth from "@/layouts/workspace/growth";
import maintain from "@/layouts/workspace/maintain";
import business from "@/layouts/workspace/business";
import cms from "@/layouts/workspace/cms";
import collect from "@/layouts/workspace/collect";
import djhdb from "@/layouts/workspace/djhdb";

const dashboard = {
    path: 'dashboard',
    name: 'workspace',
    meta: {requireAuth: true},
    component: Dashboard
}

export default <Array<RouteRecordRaw>>[
    {
        path: '',
        redirect: {name: 'workspace'}
    },
    {
        path: '/workspace',
        meta: {requireAuth: true},
        component: Layout,
        children: [dashboard, ...follow, ...growth, ...maintain, ...business, ...cms, ...collect, ...djhdb]
    },

]
