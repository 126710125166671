import {Module} from "vuex";
import DeviceRecord, {IDeviceRecord} from "@/layouts/workspace/follow/device_session_record/module/typings";
import actions from "@/layouts/workspace/follow/device_session_record/module/actions";
import mutations from "@/layouts/workspace/follow/device_session_record/module/mutations";


export default <Module<IDeviceRecord, unknown>>{
    state: new DeviceRecord(),
    mutations,
    actions
}
