import {IDBQuery, ISQLData} from "@/layouts/workspace/dashboard/dashboard";
import {req} from "@/lib/utils";
import {TableColumnsType} from "ant-design-vue";

export type Json = { [key: string]: unknown }

export interface ITableContext {
    load(): Promise<unknown>

    onSpin(fn: (spin: boolean) => void): void

    setComplete(fn: () => void): void

    title(): string

    columns(): TableColumnsType

    data(): Json[]
}

export class Table implements ITableContext {
    private dbQuery: IDBQuery
    protected draw: () => void
    protected spin: (spin: boolean) => void
    protected d: Json[] = []
    readonly name: string

    constructor(name: string, dbQuery: IDBQuery) {
        this.name = name
        this.dbQuery = dbQuery
        this.draw = (): void => {
            // empty
        }
        this.spin = (): void => {
            // empty
        }
    }

    setSql(dbQuery: IDBQuery): void {
        this.dbQuery = dbQuery
    }

    load(): Promise<unknown> {
        this.d.length = 0
        this.draw()
        this.spin(true)
        return new Promise((resolve, reject) => {
            req({
                url: 'query',
                method: 'POST',
                data: {db: this.dbQuery.db, sql: this.dbQuery.sql},
                success: (r: ISQLData) => {
                    this.d = r.list.map(item => {
                        const z = {} as Json
                        item.forEach((x, i) => {
                            z[r.columns[i]] = x
                        })
                        return z
                    })
                    resolve(r)
                    this.draw()
                },
                fail: err => reject(err),
                complete: () => this.spin(false)
            })
        })
    }

    onSpin(fn: (spin: boolean) => void): void {
        this.spin = fn
    }

    setComplete(fn: () => void): void {
        this.draw = fn
    }

    title(): string {
        return this.name
    }

    columns(): TableColumnsType {
        return this.d.length ? Object.keys(this.d[0]).map(i => ({title: i, dataIndex: i, key: i, ellipsis: false})) : []
    }

    data(): Json[] {
        return this.d;
    }

    dataFilter(predicate: (value: any, index: number, array: any[]) => unknown, thisArg?: any): void {
        this.d = this.d.filter(predicate, thisArg)
        this.draw()
    }

    dataUpdate(callbackFn: (value: Json, index: number, array: Json[]) => Json): void {
        for (let i = 0; i < this.d.length; i++) {
            this.d[i] = callbackFn(this.d[i], i, this.d)
        }
        this.draw()
    }
}

export const tables: { [key: string]: Table[] } = {
    'merchant': [new Table('微信支付商户',{
        db: 'market',
        sql: `select s.sid,s.shop_name,s.wx_mch_id,(((m."归属微信" || '+'::text) || m."微信昵称备注") || '+'::text) || COALESCE(m."加好友时间"::timestamp without time zone::text, ''::text) AS "微信备注" from shops s left join union_accounts ua on ua.sid=s.sid and ua.role=1 left join "用户账号备注" m ON m."手机号" = ua.phone_number::text where s.wx_mch_id is not null`
    })],
    'djhdb': [new Table('受邀未成交表',{
        db: 'market',
        sql: `select q.受邀手机号,qy."微信昵称备注" AS "企微备注",(((m."归属微信" || '+'::text) || m."微信昵称备注") || '+'::text) || to_char(m."加好友时间"::timestamp without time zone, 'yyyy-mm-dd hh24:mm') AS "微信备注",q.邀请次数,q.最后邀请时间 from activity.v_潜在受邀顾客 q left join "用户账号备注名" qy ON qy."手机号" = q.受邀手机号 left join "用户账号备注" m ON m."手机号" = q.受邀手机号 order by q.最后邀请时间 desc limit 1024`
    })]
}