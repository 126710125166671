import {ActionTree} from "vuex";
import DeviceRecord, {
    IRecord,
    IRecordDetail,
    ISearch
} from "@/layouts/workspace/follow/device_session_record/module/typings";
import {info, query} from "@/layouts/workspace/follow/device_session_record/apis";

export default <ActionTree<DeviceRecord, unknown>>{
    doSearch({commit}, p: ISearch): Promise<unknown> {
        p = JSON.parse(JSON.stringify(p))
        commit('updateSearch', p)
        return new Promise((resolve, reject) => {
            query(p).then((res: IRecord[]): void => {
                res = res.map(v => {
                    v.at = new Date(v.at)
                    v.url = process.env.NODE_ENV === 'production' ?
                        `https://api.console.guandb.cn/device/session/${v.url}` :
                        `https://api.console.guandb.cn/device/session/${v.url}`
                    return v
                })
                commit('updateRecords', res)
                resolve(res)
            }).catch(err => {
                reject(err)
            }).finally(() => {
                commit('searchComplete')
            })
        })
    },
    getInfo({commit}, r: IRecord): Promise<unknown> {
        commit('onLoad')
        return new Promise((resolve, reject) => {
            info(r.id).then((res: IRecordDetail): void => {
                res.url = process.env.NODE_ENV === 'production' ?
                    `https://api.console.guandb.cn/device/session/${res.url}` :
                    `https://api.console.guandb.cn/device/session/${res.url}`
                res.accounts?.forEach((a,i) => {
                    a.shops.forEach((s, ii) => {
                        res.accounts![i].shops[ii].create_at = new Date(s.create_at)
                    })
                })
                commit('updateDetail', res)
                resolve(res)
            }).catch(err => {
                reject(err)
            }).finally(() => commit('onLoadOver'))
        })
    }
}
