import {MutationTree} from "vuex";
import Session, {ISession, IToken} from "@/lib/store/modules/session/typings";

export enum StoreSessionMutations {
    login = 'login',
    tokenRefresh = 'tokenRefresh',
    logout = 'logout'
}


export default <MutationTree<Session>>{
    [StoreSessionMutations.login](state: Session, res: ISession) {
        state.account = res.account
        state.token = res.token
        state.menu = res.menu
        localStorage.session = JSON.stringify(state)
    },
    [StoreSessionMutations.tokenRefresh](state: Session, res: IToken) {
        state.token = res
        localStorage.session = JSON.stringify(state)
    },
    [StoreSessionMutations.logout](state: Session) {
        state.token.token = ''
        state.token.expire = new Date()
        state.account = {id: '', head_img_url: "", nick_name: "", wx_open_id: ''}
        state.token = {expire: new Date(), token: ''}
        state.menu = []
    }
}
