import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'maintain/push',
        name: 'maintain.push',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "maintain_push" */ './push/Push.vue')
    },
    {
        path: 'maintain/microservice',
        name: 'maintain.microservice',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "maintain_microservice" */ './microservice/Microservice.vue')
    },
    {
        path: 'maintain/job',
        name: 'maintain.job',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "maintain_job" */ './job/Job.vue')
    },
    {
        path: 'maintain/ip/info',
        name: 'maintain.ip.info',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "maintain_ip_info" */ './ip_info/IpInfo.vue')
    },
    {
        path: 'maintain/statistics',
        name: 'maintain.statistics',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "maintain_statistics" */ './statistics/Statistics.vue')
    },
]
