import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'company/follow/up',
        name: 'company.follow.up',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "company_follow_up" */ './company_follow_up/CompanyFollowUp.vue')
    },
    {
        path: 'wework/group/chats',
        name: 'wework.group.chats',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "wework_group_chats" */ './wework_group_chats/WeworkGroupChats.vue')
    },
]
