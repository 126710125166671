import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'business/mp/weixin/session',
        name: 'business.mp.weixin.session',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "business_mp_weixin_session" */ './mp_weixin_session/MpWeixinSession.vue')
    },
    {
        path: 'business/invite',
        name: 'business.invite',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "business_invite" */ './invite/InvitePage.vue')
    },
    {
        path: 'business/leads',
        name: 'business.leads',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "business_leads" */ './leads/LeadsPage.vue')
    },
]
